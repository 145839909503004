import { AnimationEvent } from '@angular/animations';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Signal, signal, WritableSignal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { KeyboardService } from '@stsm/shared/services/keyboard.service';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DotBadgeComponent } from '@stsm/ui-components/dot-badge/dot-badge.component';

import {
  AiAssistantStore,
  AiAssistantViewModel,
  AssistantAnimationState,
  isChatAnimationState,
} from '../../services/ai-assistant-store.service';
import { AiAssistantComponent } from '../ai-assistant/ai-assistant.component';
import { aiAssistantAnimations } from '../ai-assistant/ai-assistant-animations';
import { ChatTopRowComponent } from '../chat-top-row/chat-top-row.component';

@UntilDestroy()
@Component({
  selector: 'app-ai-assistant-button',
  standalone: true,
  imports: [AiAssistantComponent, AsyncPipe, ButtonComponent, ChatTopRowComponent, NgTemplateOutlet, DotBadgeComponent],
  templateUrl: './ai-assistant-button.component.html',
  styleUrl: './ai-assistant-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    aiAssistantAnimations.assistantPositioningContainerAnimation,
    aiAssistantAnimations.assistantLayoutContainerAnimation,
    aiAssistantAnimations.assistantContentAnimation,
  ],
  host: {
    '[@aiAssistant]': '{ value: vm.animationState(), params: { chatHeight: chatHeight() } }',
    '(@aiAssistant.done)': `onAnimationEnd($event)`,
    '[class.is-full-page-route]': 'isFullPageLayout()',
    'data-cy': 'ai-assistant-button',
  },
})
export class AiAssistantButtonComponent {
  protected readonly vm: AiAssistantViewModel = this._aiAssistantStore.vm;
  protected readonly showChatContent: WritableSignal<boolean> = signal(false);
  protected readonly isFullPageLayout: Signal<boolean> = this._aiAssistantStore.isFullPageLayout;
  protected readonly showNotificationBadge: Signal<boolean> = computed(() => this.vm.showNotificationBadge() && !this.vm.isChatOpen());
  protected readonly hasReducedHeight: Signal<boolean> = computed(
    () => this.vm.hasReducedHeight() && !this._keyboardService.isKeyboardVisible(),
  );

  protected readonly chatHeight: Signal<string> = computed(() => {
    // assistant exceeds viewport height in mobile browser
    // reserve space for browser controls
    if (this._layoutStore.isWebAppInPhoneLayout()) {
      return '80vh';
    }

    return this.hasReducedHeight() ? '60vh' : '100vh';
  });

  constructor(
    private readonly _aiAssistantStore: AiAssistantStore,
    private readonly _keyboardService: KeyboardService,
    private readonly _layoutStore: LayoutStore,
  ) {}

  onAnimationEnd(event: AnimationEvent): void {
    const previousAnimationState = event.fromState as AssistantAnimationState;
    const currentAnimationState = event.toState as AssistantAnimationState;
    this.showChatContent.set(!isChatAnimationState(previousAnimationState) && isChatAnimationState(currentAnimationState));
  }

  onCloseClick(): void {
    if (this.vm.animationState() === 'pill') {
      this._aiAssistantStore.resetPillMessage();
    } else {
      this._aiAssistantStore.closeChat();
    }
  }
}
