@if (showNotificationBadge()) {
  <div class="badge-wrapper">
    <ui-dot-badge notificationBadge [badgeValue]="true" />
  </div>
}

<div class="gradient-border" [@layoutContainer]="vm.animationState()">
  <div class="container">
    <div class="content" [@content]="vm.animationState()">
      <app-ai-assistant [showChatContent]="showChatContent()" (closeAssistant)="onCloseClick()" />
    </div>
  </div>
</div>
