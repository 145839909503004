import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  Inject,
  Input,
  OnInit,
  Signal,
  signal,
  TrackByFunction,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Update } from '@ngrx/entity';
import { IconFontName } from '@studysmarter/common-styles/lib/icon-font';
import { isNil } from 'lodash-es';
import {
  catchError,
  combineLatest,
  finalize,
  firstValueFrom,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { emailPattern } from '@stsm/auth/feature/validation/patterns';
import { NiceDatePipe } from '@stsm/date/pipes/nice-date.pipe';
import { Slideset } from '@stsm/documents/models/slideset';
import { SlidesetsService } from '@stsm/documents/services/slidesets.service';
import { SlidesetsStoreFacade } from '@stsm/documents/store/slidesets-store-facade.service';
import { BrowserService } from '@stsm/global/composite/services/browser.service';
import { SharedState } from '@stsm/global/models/shared-state';
import { ZendeskArticleType } from '@stsm/global/models/zendesk-article-type';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { isOfflineError } from '@stsm/offline-mode/models/offline-error';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { KeyboardService } from '@stsm/shared/services/keyboard.service';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { Id } from '@stsm/shared/types/id';
import { trackByDefault, trackByObjectId } from '@stsm/shared/util/generic-utils';
import { shareIconName } from '@stsm/shared/util/platform-util';
import { filterUndefined, shareReplayRefCount, VOID } from '@stsm/shared/util/rxjs.util';
import { Studygroup } from '@stsm/studygroups/models/studygroup';
import { StudygroupMember } from '@stsm/studygroups/models/studygroup-member';
import { StudygroupUpdateBuilder } from '@stsm/studygroups/models/studygroup-update-builder';
import { StudygroupsStoreFacade } from '@stsm/studygroups/services/store/studygroups-store-facade.service';
import { StudygroupsService } from '@stsm/studygroups/services/studygroups.service';
import { Studyset } from '@stsm/studysets/models/studyset';
import {
  hasNoBulkOperationErrors,
  StudysetEntityBulkOperation,
  StudysetEntityBulkOperationResponse,
} from '@stsm/studysets/models/studyset-bulk-operation';
import { ShareStudysetLocation } from '@stsm/studysets/models/studyset-share-location';
import { StudysetUpdateBuilder, StudysetUpdateBuilderOnline } from '@stsm/studysets/models/studyset-update-builder';
import { StudysetMessageService } from '@stsm/studysets/services/studyset-message.service';
import { StudysetsBaseService } from '@stsm/studysets/services/studysets-base.service';
import { STUDYSETS_SERVICE } from '@stsm/studysets/services/tokens/studysets-service.token';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import { userIsCreator } from '@stsm/studysets/util/functions/user-is-creator';
import { Summary } from '@stsm/summaries/models/summary';
import { SummaryService } from '@stsm/summaries/services/summary.service';
import { SummariesStoreFacade } from '@stsm/summaries/store/summaries-store-facade.service';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogHeaderComponent } from '@stsm/ui-components/dialogs/components/dialog-header/dialog-header.component';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { ThemedDirective } from '@stsm/ui-components/disco-color';
import { FormErrorComponent, FormFieldComponent, SuffixComponent } from '@stsm/ui-components/form-field';
import { InputDirective } from '@stsm/ui-components/input';
import { LoadingService } from '@stsm/ui-components/loading-dialog';
import { TagComponent } from '@stsm/ui-components/tag';
import { TooltipDirective } from '@stsm/ui-components/tooltip';
import { VisibilityBadgeComponent } from '@stsm/ui-components/visibility-badge/visibility-badge.component';

import { CollaboratorsTooltipComponentComponent } from './collaborators-tooltip/collaborators-tooltip.component';
import {
  MaterialVisibilityDialogComponent,
  MaterialVisibilityDialogData,
  MaterialVisibilityResult,
} from './material-visibility-dialog/material-visibility-dialog.component';
import { StudygroupEmailComponent } from './studygroup-email/studygroup-email.component';
import { StudygroupSettingsComponent } from './studygroup-settings/studygroup-settings.component';

@UntilDestroy()
@Component({
  selector: 'app-share-studyset-dialog',
  templateUrl: './share-studyset-dialog.component.html',
  styleUrls: ['./share-studyset-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslatePipe,
    ReactiveFormsModule,
    FormsModule,
    StudygroupEmailComponent,
    AsyncPipe,
    NgIf,
    NgForOf,
    ButtonComponent,
    FormFieldComponent,
    InputDirective,
    StudygroupSettingsComponent,
    DialogHeaderComponent,
    DialogTemplateComponent,
    TagComponent,
    ThemedDirective,
    VisibilityBadgeComponent,
    NiceDatePipe,
    TooltipDirective,
    CollaboratorsTooltipComponentComponent,
    IconButtonComponent,
    FormErrorComponent,
    SuffixComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'share-studyset-dialog',
  },
})
export class ShareStudysetDialogComponent implements OnInit {
  @Input({ required: true }) set studysetId(studysetId: number) {
    this._studysetId$.next(studysetId);
  }

  isAdmin: boolean = false;
  userId: number | undefined = this._authStore.userId;
  studygroup: Studygroup | undefined;
  emailForm: FormGroup<{ email: FormControl<string> }>;
  showSuggestions: WritableSignal<boolean> = signal(false);
  filteredSuggestedEmails: string[] = [];
  readonly shareIconName: IconFontName = shareIconName;

  trackByObjectId: TrackByFunction<StudygroupMember> = trackByObjectId;
  trackByDefault: TrackByFunction<string> = trackByDefault;

  isUserCreator: boolean = false;

  get emailControl(): FormControl<string> {
    return this.emailForm.controls.email;
  }

  get isShareViaLinkButtonDisabled(): boolean {
    return !this.studygroup?.allowJoining || (!this.isAdmin && !this.studygroup?.allowInviting);
  }

  get showFooter(): boolean {
    return this.studyset?.shared || this.isUserCreator;
  }

  get publishedAt(): string | undefined {
    return this.studyset?.publishedAt;
  }

  protected studyset: Studyset | undefined;

  protected readonly isKeyboardVisible$: Observable<boolean> = this._keyboardService.isKeyboardVisible$;
  protected readonly showHelp: Signal<boolean> = computed(() => this._targetMarketProvider.targetMarket() === 'core');

  private readonly _allSuggestedEmails$: Subject<string[]> = new Subject();

  private readonly _studysetId$: ReplaySubject<number> = new ReplaySubject<number>(1);
  private readonly _studyset$: ReplaySubject<Studyset> = new ReplaySubject<Studyset>(1);

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _studygroupsService: StudygroupsService,
    private readonly _authStore: AuthStore,
    private readonly _loadingService: LoadingService,
    private readonly _toastService: ToastService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _translationService: TranslationService,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _studygroupsStoreFacade: StudygroupsStoreFacade,
    @Inject(STUDYSETS_SERVICE) private readonly _studysetsService: StudysetsBaseService,
    private readonly _dialogRef: DialogRef,
    private readonly _targetMarketProvider: TargetMarketProvider,
    private readonly _browserService: BrowserService,
    private readonly _loggerService: LoggerService,
    private readonly _keyboardService: KeyboardService,
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
    private readonly _platformModalService: PlatformModalService,
    private readonly _slidesetsStoreFacade: SlidesetsStoreFacade,
    private readonly _summariesStoreFacade: SummariesStoreFacade,
    private readonly _studysetMessageService: StudysetMessageService,
    private readonly _slidesetsService: SlidesetsService,
    private readonly _summaryService: SummaryService,
  ) {
    this.emailForm = new FormGroup<{ email: FormControl<string> }>({
      email: new FormControl<string>('', { nonNullable: true, validators: [Validators.required, Validators.pattern(emailPattern())] }),
    });

    this._studysetId$
      .pipe(
        switchMap((studysetId: number) => this._studysetsStoreFacade.studysetById(studysetId)),
        untilDestroyed(this),
      )
      .subscribe((studyset: Studyset) => {
        this._studyset$.next(studyset);
        this.studyset = studyset;
        this.isUserCreator = userIsCreator(studyset);
        this._changeDetectorRef.markForCheck();
      });

    this._studyset$
      .pipe(
        take(1),
        switchMap((studyset: Studyset) => this._studygroupsService.fetchStudygroup(studyset)),
        untilDestroyed(this),
      )
      .subscribe({
        error: (error: unknown) => {
          this._loggerService.warn(error);

          this._simpleDialogService.showAlertMessage('STUDYGROUP.ERROR_STUDYGROUP_GET', () => this._dialogRef.dismiss());
        },
      });

    const studygroup$: Observable<Studygroup> = this._studyset$.pipe(
      switchMap((studyset: Studyset) => this._studygroupsStoreFacade.studygroupById(studyset.studygroupId)),
      filterUndefined(),
      shareReplayRefCount(1),
    );

    studygroup$.pipe(untilDestroyed(this)).subscribe((studygroup: Studygroup) => {
      this.studygroup = studygroup;
      this.isAdmin = this.userId === this.studygroup.admin;
      this._changeDetectorRef.markForCheck();
    });

    combineLatest([studygroup$, this._allSuggestedEmails$])
      .pipe(untilDestroyed(this))
      .subscribe(([studygroup, allSuggestedEmails]: [Studygroup, string[]]) => {
        this.filteredSuggestedEmails = allSuggestedEmails.filter(
          (email: string) => !studygroup.pendingEmails.includes(email) && !this._suggestedEmailContained(studygroup, email),
        );
        this._changeDetectorRef.markForCheck();
      });
  }

  ngOnInit(): void {
    this._analyticsService.trackEvent({ action: 'studyset_collaboration_settings_open' });

    if (!isNil(this.studyset) && !this.studyset.shared) {
      forkJoin([this._slidesetsService.fetchSlidesets(this.studyset), this._summaryService.fetchSummaries(this.studyset.id)])
        .pipe(untilDestroyed(this))
        .subscribe();
    }

    this._getSuggestedEmails()
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        error: (error: Error) => {
          this._loggerService.warn(error);
        },
      });
  }

  onInputFocus(): void {
    this.showSuggestions.set(true);
  }

  onInputBlur(): void {
    setTimeout(() => {
      this.showSuggestions.set(false);
    }, 250); // use timeout value to wait until hiding to allow click in suggestions
  }

  shareStudygroupViaLink(): void {
    if (isNil(this.studyset) || isNil(this.studygroup)) {
      return;
    }

    this._studysetMessageService.triggerShareStudygroup(this.studyset, this.studygroup);
  }

  shareStudyset(): void {
    if (isNil(this.studyset)) {
      return;
    }
    this._studysetMessageService.triggerShareStudyset(this.studyset, ShareStudysetLocation.STUDYGROUP);
  }

  sendInvite(invitedEmail: string): void {
    if (isNil(this.studyset)) {
      return;
    }

    this._loadingService.showLoading();
    this._studygroupsService
      .sendInvite(this.studyset, invitedEmail)
      .pipe(
        finalize(() => this._loadingService.hideLoading()),
        take(1),
        untilDestroyed(this),
      )
      .subscribe({
        next: () => {
          if (!isNil(this.studygroup)) {
            this._studygroupsStoreFacade.updateStudygroup(
              new StudygroupUpdateBuilder(this.studygroup).addPendingEmail(invitedEmail).partialUpdate,
            );
            this.emailForm.reset();
            this._toastService.successToast('STUDYGROUP.EMAIL_SENT');

            this._analyticsService.trackEvent({ action: 'studygroup_invite_mail' });
            this._analyticsService.trackSingularEvent('friend_invite_any');
          }
        },
        error: (error: Error) => {
          this._loggerService.warn(error);
          this._showErrorAlert(error);
        },
      });
  }

  onSubmit(): void {
    if (this.emailForm.invalid) {
      return;
    }
    const invitedEmail = this.emailControl.value;
    this.sendInvite(invitedEmail);
  }

  onRemoveMember(memberId: number, index: number): void {
    if (isNil(this.studygroup)) {
      return;
    }

    const member = this.studygroup.members[index];

    if (isNil(member)) {
      return;
    }

    this._simpleDialogService
      .scheduleConfirm({
        heading: this._translationService.get('STUDYGROUP.REMOVE_MEMBER', { member: member.email }),
      })
      .pipe(take(1), untilDestroyed(this))
      .subscribe((hasConfirmed: boolean): void => {
        if (hasConfirmed) {
          this._removeMember(memberId);
        }
      });
  }

  onRemoveInvite(email: string): void {
    this._simpleDialogService
      .scheduleConfirm({
        heading: this._translationService.get('STUDYGROUP.REMOVE_INVITE', { member: email }),
      })
      .pipe(take(1), untilDestroyed(this))
      .subscribe((hasConfirmed: boolean): void => {
        if (hasConfirmed) {
          this._removeInvite(email);
        }
      });
  }

  async onShareStudyset(): Promise<void> {
    if (isNil(this.studyset)) {
      return;
    }

    const slidesets: Slideset[] = await firstValueFrom(this._slidesetsStoreFacade.slidesets(this.studyset.id));
    const summaries: Summary[] = await firstValueFrom(this._summariesStoreFacade.summaries(this.studyset.id));

    const dialogRef: DialogRef<MaterialVisibilityDialogComponent, MaterialVisibilityResult> = this._platformModalService.create<
      MaterialVisibilityDialogData,
      MaterialVisibilityDialogComponent,
      MaterialVisibilityResult
    >({
      component: MaterialVisibilityDialogComponent,
      data: {
        slidesets,
        summaries,
      },
    });

    const result: false | MaterialVisibilityResult = await firstValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    }

    this._loadingService.showLoading();

    const didBulkUpdateSucceed: boolean = await this._bulkUpdateMaterialSharedStates(result);

    if (!didBulkUpdateSucceed) {
      this._simpleDialogService.showError('SHARE.MATERIAL_VISIBILITY.ERROR.SHARE_MATERIALS');
      this._loadingService.hideLoading();

      return;
    }

    if (this.isAdmin && !isNil(this.studyset) && !isNil(this.studyset.parentId)) {
      this._shareRootStudyset(this.studyset.parentId, this.studyset.id);
    } else {
      this._shareStudyset();
    }
  }

  openHelp(): void {
    this._browserService.openZendeskHelpLink(ZendeskArticleType.STUDYGROUPS, () => {
      this._simpleDialogService.showAlertMessage('ERROR.ERROR_OPEN_LINK');
    });
  }

  private async _bulkUpdateMaterialSharedStates(
    result: MaterialVisibilityResult,
    sharedState: SharedState = SharedState.PUBLIC,
  ): Promise<boolean> {
    if (isNil(this.studyset)) {
      return false;
    }

    const updates: StudysetEntityBulkOperation[] = [];

    for (const key in result) {
      const ids: Id[] = result[key as keyof MaterialVisibilityResult];
      updates.push({
        ids,
        entity: key === 'slidesetIds' ? 'slideset' : 'summary',
        values: {
          shared: sharedState,
        },
        operation: 'update',
      });
    }

    const request$: Observable<StudysetEntityBulkOperationResponse[] | undefined> = this._studysetsService
      .studysetEntityBulkOperation(this.studyset.id, updates)
      .pipe(
        catchError((error: Error) => {
          this._loggerService.debug(`failed to bulk update studyset entities`, error, updates);

          return of(undefined);
        }),
      );

    const updateResult: StudysetEntityBulkOperationResponse[] | undefined = await firstValueFrom(request$, { defaultValue: undefined });

    const hasNoErrors: boolean = hasNoBulkOperationErrors(updateResult);

    if (hasNoErrors) {
      this._slidesetsStoreFacade.updateMany(result.slidesetIds.map((id: Id): Update<Slideset> => ({ id, changes: { sharedState } })));

      this._summariesStoreFacade.updateMany(result.summaryIds.map((id: Id): Update<Summary> => ({ id, changes: { sharedState } })));
    }

    return hasNoErrors;
  }

  private _shareStudyset(): void {
    if (isNil(this.studyset)) {
      return;
    }

    const builder = new StudysetUpdateBuilder(this.studyset).shared(true);

    this._studysetsService
      .updateStudyset(builder)
      .pipe(
        finalize(() => this._loadingService.hideLoading()),
        untilDestroyed(this),
      )
      .subscribe({
        next: (studyset: Studyset) => {
          this.studyset = studyset;
          this._analyticsService.trackEvent({ action: 'studygroup_make_public' });
          this._toastService.successToast('STUDYGROUP.SHARED');
        },
        error: (error: Error) => {
          this._loggerService.warn(error);
          this._simpleDialogService.showError('ERROR.ERROR_SUBJECT_EDIT');
        },
      });
  }

  private _shareRootStudyset(rootStudysetId: Id, subtopicId: Id): void {
    const builder = new StudysetUpdateBuilderOnline(rootStudysetId).shared(true);

    this._studysetsService
      .updateStudyset(builder)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this._studysetsStoreFacade.updateStudyset({ id: subtopicId, changes: { shared: true } });
          this._analyticsService.trackEvent({ action: 'studygroup_make_public' });

          this._loadingService.hideLoading();
          this._toastService.successToast('STUDYGROUP.SHARED');
        },
        error: (error: Error): void => {
          this._loadingService.hideLoading();
          this._loggerService.warn(error);
          this._simpleDialogService.showError('ERROR.ERROR_SUBJECT_EDIT');
        },
      });
  }

  private _removeMember(memberId: number): void {
    if (isNil(this.studyset)) {
      return;
    }

    this._loadingService.showLoading();
    this._studygroupsService
      .removeMember(this.studyset, memberId)
      .pipe(
        finalize(() => this._loadingService.hideLoading()),
        take(1),
        untilDestroyed(this),
      )
      .subscribe({
        next: () => {
          if (!isNil(this.studygroup)) {
            this._studygroupsStoreFacade.updateStudygroup(
              new StudygroupUpdateBuilder(this.studygroup).removeMember(memberId).partialUpdate,
            );
            this._analyticsService.trackEvent({ action: 'studygroup_remove_member' });
          }
        },
        error: (error: Error) => {
          this._loggerService.warn(error);
          this._showErrorAlert(error);
        },
      });
  }

  private _removeInvite(email: string): void {
    if (isNil(this.studyset)) {
      return;
    }

    this._loadingService.showLoading();
    this._studygroupsService
      .removeInvite(this.studyset, email)
      .pipe(
        finalize(() => this._loadingService.hideLoading()),
        take(1),
        untilDestroyed(this),
      )
      .subscribe({
        next: () => {
          if (!isNil(this.studygroup)) {
            this._studygroupsStoreFacade.updateStudygroup(
              new StudygroupUpdateBuilder(this.studygroup).removePendingEmail(email).partialUpdate,
            );
            this._analyticsService.trackEvent({ action: 'studygroup_remove_invite' });
          }
        },
        error: (error: Error) => {
          this._loggerService.warn(error);
          this._showErrorAlert(error);
        },
      });
  }

  private _getSuggestedEmails(): Observable<void | string[]> {
    return this._studygroupsService.getSuggestedEmails().pipe(
      tap((emails: string[]) => this._allSuggestedEmails$.next(emails)),
      catchError((error: Error) => {
        this._loggerService.warn(error);

        return VOID;
      }),
    );
  }

  private _suggestedEmailContained(studygroup: Studygroup, email: string): boolean {
    return studygroup.members.some((member: StudygroupMember) => {
      return member.email.toLowerCase() === email.toLowerCase();
    });
  }

  private _showErrorAlert(error: Error): void {
    if (isOfflineError(error)) {
      this._simpleDialogService.showAlertMessage(error.type);
    } else {
      this._simpleDialogService.showError();
    }
  }
}
