import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AiAssistantStore } from '../services/ai-assistant-store.service';

/**
 * Use this guard for routes where the data param {@link AI_ASSISTANT_ENABLED} does not work.
 */
export const disableAiAssistantGuard: CanActivateFn = () => {
  const aiAssistantStore = inject(AiAssistantStore);
  aiAssistantStore.disableAssistantForCurrentRoute();

  return true;
};
